import header from './header';
import landing from './landing';
import registration from './registration';
import signin from './signin';
import forgottenPassword from './forgotten_password';
import resetPassword from './reset_password';

export default {
  header,
  landing,
  registration,
  signin,
  forgotten_password: forgottenPassword,
  reset_password: resetPassword,
};
