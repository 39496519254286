export default {
  fields: {
    gender: {
      values: {
        MALE: 'Male',
        FEMALE: 'Female',
        NON_BINARY: 'Non-Binary',
      },
    },
  },
  content: {
    disclaimer: {
      content: [
        'part1.key',
        'part2.key' ],
    },
    name: {
      content: [
        'part1.key',
      ],
    },
    email: {
      content: [
        'part1.key',
      ],
    },
    know_you: {
      content: [
        'part1.key',
      ],
    },
    summary: {
      content: [
        'part1.key',
      ],
    },
    password: {
      content: [ 'part1.key' ],
    },
    passwordConfirmation: {
      content: [ 'part1.key' ],
    },
  },
};
