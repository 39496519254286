import { render, staticRenderFns } from "./element-part-audio.vue?vue&type=template&id=62b7a6fa&"
import script from "./element-part-audio.vue?vue&type=script&lang=ts&"
export * from "./element-part-audio.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62b7a6fa')) {
      api.createRecord('62b7a6fa', component.options)
    } else {
      api.reload('62b7a6fa', component.options)
    }
    module.hot.accept("./element-part-audio.vue?vue&type=template&id=62b7a6fa&", function () {
      api.rerender('62b7a6fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements-parts/element-part-audio.vue"
export default component.exports